import React, { useState } from 'react';
import { withStore } from 'contexts/store-context';
import { withToast } from 'contexts/toast-context';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/loading/loading-indicator';
import FAWrapper from 'components/vendors/fa-wrapper';
import { Button, DropdownToggle, DropdownItem } from 'reactstrap';
import { DropdownWrapper, DropdownMenuWrapper } from 'components/bootstrap/dropdowns';

const BookingManager = (props) => {
  const { type, booking, isAdmin, className = '', store, toast, refreshData } = props;
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const [ taskRunning, setTaskRunning ] = useState(false);
  const [ status, setStatus ] = useState({});

  // Tasks
const saveBooking = async (booking, status) => {
  try {
    setStatus(status);
    setTaskRunning(true);
    store.adapterFor('booking').set('action', status);
    await store.queryRecord('booking', booking.id);
    store.adapterFor('booking').set('action', null);
    status === 'delay' || status === 'transit' ? alert(`Customer has been notified that you are ${status === 'delay' ? 'running late' : 'on the way'}`) : toast.showSuccess(`Booking saved!`);
    refreshData();
  } catch(e) {
    toast.showError(e);
  } finally {
    setTaskRunning(false);
  }
}

// Methods
const changeState = (booking, status) => {
  let confirmed = confirm(`Are you sure you'd like to ${status} this booking? ${booking.statusMsg(status)}.`)
  if (confirmed) {
    saveBooking(booking, status);
  }
}

  // Render
  if (booking.isDraft) {
    return (
      <div className='flex-end pr-15 mr-15 br'>
        <Button color='primary' size='md' className='mr-15' onClick={() => changeState(booking, 'request')}>
          {taskRunning && status == 'request' ? <LoadingIndicator color='white' size='18px'/> : <span>Activate</span>}
        </Button>
      </div>
    )
  }

  if (booking.isRequested) {
    return (
      <div className='flex-end pr-15 mr-15 br'>
        <Button color='primary' size='md' className='mr-15' onClick={() => changeState(booking, 'accept')}>
          {taskRunning && status == 'accept'  ? <LoadingIndicator color='white' size='18px'/> : <span>Accept</span>}
        </Button>
        <Button color='danger' size='md' onClick={() => changeState(booking, 'deny')}>
          {taskRunning && status == 'deny'  ? <LoadingIndicator color='white' size='18px'/> : <span>Deny</span>}
        </Button>
      </div>
    )
  }
  if (booking.isAccepted) {
    return (
      <div className='flex-end pr-15 mr-15 br'>
        <DropdownWrapper isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
          <DropdownToggle color='gray' size='sm'>
            <span>Actions<FAWrapper icon='chevron-down' className='ml-2 sm'/></span>
          </DropdownToggle>
          <DropdownMenuWrapper isOpen={true} right={true}>
            <DropdownItem tag='li'>
              <Button color='transparent' onClick={() => changeState(booking, 'finish')}>Finish</Button>
            </DropdownItem>

            {/* remove cancel booking option for provider as per requirement */}
            {/* <DropdownItem tag='li'>
               <Button color='transparent' onClick={() => changeState(booking, 'cancel')}>Cancel</Button>
             </DropdownItem> */}

            <DropdownItem tag='li'>
              <Button color='transparent' onClick={() => changeState(booking, 'delay')}>Delay</Button>
            </DropdownItem>

            <DropdownItem tag='li'>
              <Button color='transparent' onClick={() => changeState(booking, 'transit')}>On The Way</Button>
            </DropdownItem>
          </DropdownMenuWrapper>
        </DropdownWrapper>
      </div>
    )
  }
  if (booking.status === 'delay' || booking.status === 'transit') {
    return (
      <div className='flex-end pr-15 mr-15 br'>
        <DropdownWrapper isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
          <DropdownToggle color='gray' size='sm'>
            <span>Actions<FAWrapper icon='chevron-down' className='ml-2 sm'/></span>
          </DropdownToggle>
          <DropdownMenuWrapper isOpen={true} right={true}>
            <DropdownItem tag='li'>
              <Button color='transparent' onClick={() => changeState(booking, 'finish')}>Finish</Button>
            </DropdownItem>
          {/* remove cancel booking option for provider as per requirement */}
             {/* <DropdownItem tag='li'>
               <Button color='transparent' onClick={() => changeState(booking, 'cancel')}>Cancel</Button>
             </DropdownItem> */}
          </DropdownMenuWrapper>
        </DropdownWrapper>
        {/* <Button color='primary' size='md' className='mr-15' onClick={() => changeState(booking, 'finish')}>
          {taskRunning && status == 'finish'  ? <LoadingIndicator color='white' size='18px'/> : <span>Finish</span>}
        </Button>
        <Button color='danger' size='md' className='mr-15' onClick={() => changeState(booking, 'cancel')}>
          {taskRunning && status == 'cancel'  ? <LoadingIndicator color='white' size='18px'/> : <span>Cancel</span>}
        </Button> */}
      </div>
    )
  }

  return <span className='mr-15'>{booking.isFinished && <i>This booking has finished</i>}</span>
}

export default withStore(withToast(BookingManager));
